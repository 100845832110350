import axios from "axios";
import { cloudRenderingSessionManagementServiceUrl } from "../config";
import type {
  CloudRenderingRegion,
  RegionFilter,
  SessionRating,
  UserPreferences,
  VmSizeInformation,
} from "../hooks/types";
import { DeviceType } from "../session/types";

const sessionManagementApiClient = axios.create({
  baseURL: cloudRenderingSessionManagementServiceUrl,
});

export default sessionManagementApiClient;

export async function getUserPreferences(): Promise<UserPreferences> {
  const response =
    await sessionManagementApiClient.get<UserPreferences>("/Users/Preferences");
  return response.data;
}

export async function setUserPreferences(userPreferences: UserPreferences) {
  const response = await sessionManagementApiClient.post("/Users/Preferences", {
    ...userPreferences,
    preferredRenderingRegion: undefined,
  });
  return response.data;
}

export async function rateSession(sessionId: string, rating: SessionRating) {
  const response = await sessionManagementApiClient.patch(
    `/Sessions/${sessionId}/Rate`,
    rating,
  );
  return response.data;
}

export async function getVmSizes(): Promise<VmSizeInformation[]> {
  const response = await sessionManagementApiClient.get<{
    vmSizes: VmSizeInformation[];
  }>("/VirtualMachines/Sizes");
  return response.data.vmSizes;
}

export async function getOrderedCloudRenderingRegions({
  appIds,
  ...filters
}: RegionFilter = {}): Promise<CloudRenderingRegion[]> {
  const response = await sessionManagementApiClient.post("/Regions", {
    appIds: appIds?.map(String),
    ...filters,
  });
  return response.data.regions;
}

interface SessionDetailsResponse {
  id: string;
  sessionCreatedDateTime: string;
  sessionTerminatedDateTime: string | null;
  experienceStartedDateTime: string | null;
}

export async function getSessionDetails(sessionId: string) {
  const response = await sessionManagementApiClient.get<SessionDetailsResponse>(
    `/Sessions/${sessionId}`,
  );
  return response.data;
}

export type PaginatedList<TType> = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  items: TType[];
  pageNumber: number;
  totalPages: number;
  totalResults: number;
};

export interface VmFilter {
  includeDestroyed?: boolean;
}

export interface VirtualMachine {
  id: string;
  region: string;
  regionDisplayName: string;
  size: string;
  sizeDisplayName: string;
  activeUserIdentifier?: string;
  privateIp?: string;
  publicIp?: string;
  image: string;
  pulumiState: string;
  state: string;
  organizationId?: string;
  reservedInstanceId?: string;
  isStarted: boolean;
  isReady: boolean;
  isHealthy: boolean;
  debugModeEnabled: boolean;
  dateTimeCreated: string;
  firstTimeReadyDateTime?: string;
  expirationDateTime: string;
}

export type VirtualMachineList = PaginatedList<VirtualMachine>;

export async function getVirtualMachines(
  params: VmFilter,
): Promise<VirtualMachineList> {
  const response = await sessionManagementApiClient.get<VirtualMachineList>(
    "/VirtualMachines",
    {
      params: params,
    },
  );

  return response.data;
}

export async function getVirtualMachine(id: string): Promise<VirtualMachine> {
  const response = await sessionManagementApiClient.get<VirtualMachine>(
    `/VirtualMachines/${id}`,
  );

  return response.data;
}

export async function destroyVirtualMachine(
  id: string,
): Promise<VirtualMachine> {
  return await sessionManagementApiClient.post(
    `/VirtualMachines/${id}/Destroy`,
  );
}

const cloudXREnryptionPolicyOptions = [
  "Disallowed",
  "OptIn",
  "OptOut",
  "Enforced",
] as const;

type CloudXREncryptionPolicy = (typeof cloudXREnryptionPolicyOptions)[number];

type OrganizationCloudRenderingPolicy = {
  cloudXREncryption: CloudXREncryptionPolicy;
};

export async function getOrganizationCloudRenderingPolicies() {
  return (
    await sessionManagementApiClient.get<OrganizationCloudRenderingPolicy>(
      "/Organizations/Settings",
    )
  ).data;
}

export interface CreateVirtualMachine {
  region: string;
  size: string;
  image?: string;
  expirationTimeSpan: string;
  debugModeEnabled: boolean;
  preInstalledAppBuildIds?: string[];
}

export async function createVirtualMachine(
  vm: CreateVirtualMachine,
): Promise<VirtualMachine> {
  return (await sessionManagementApiClient.post("/VirtualMachines", vm)).data;
}

export type TargetPlatform = "Windows" | "Android";

export interface SessionFilters {
  page?: number;
  pageSize?: number;
  from?: Date;
  to?: Date;
  deviceType?: "VrStandalone" | "Browser" | "PcDesktopClient";
  targetPlatform?: TargetPlatform;
  renderType?: "LocalRendered" | "CloudRendered";
  userIdentifier?: (string | number)[];
  organizationId?: string | number;
  appId?: string | number;
}

export interface Session {
  id: string;
  userIdentifier: string;
  appId: string;
  appBuildId: string;
  appTargetPlatform: TargetPlatform;
  deviceIdentifier: string;
  deviceType: DeviceType;
  organizationId: string;
  experienceStartedDateTime: string;
  sessionTerminatedDateTime: string;
  renderRegion?: string;
  vmSize?: string;
}

export async function getSessionsPaginated({
  userIdentifier,
  ...filters
}: SessionFilters = {}): Promise<SessionList> {
  const data = (
    await sessionManagementApiClient.get<PaginatedList<Session>>(
      "/v2/Sessions",
      {
        params: { userIdentifier: userIdentifier?.join(","), ...filters },
      },
    )
  ).data;

  return { ...data, items: data.items };
}

export type SessionList = PaginatedList<Session>;
