import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Link,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  WrapItem,
  useBoolean,
  useColorModeValue,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useMemo, useRef, useState } from "react";
import { BsSearch as SearchIcon } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";
import { generatePath } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { useLocalStorage } from "usehooks-ts";
import { useAuthentication } from "../auth";
import {
  useActiveOrganizationQuery,
  useApplicationQuery,
  useBestCloudRenderingRegionQuery,
  useCloudRenderingRegionsQuery,
  useFeatureFlags,
  useSearchApplicationQuery,
} from "../hooks";
import { useApplicationsQuery } from "../hooks/useApplicationsQuery";
import { Region } from "../hooks/useCloudRenderingRegionsQuery";
import { useCreateVirtualMachineMutation } from "../hooks/useCreateVirtualMachineMutation";
import { useVmSizesQuery } from "../hooks/useVmSizesQuery";
import { routes } from "../utils/routes";
import { AppPreviewSkeleton, ApplicationPreviewImage } from "./AppPreview";
import { BrandedSkeleton } from "./BrandedSkeleton";
import { CloudRenderingRegionsTable } from "./CloudRenderingRegionsTable";
import { VmSizeSelector } from "./VmSizeSelector";
import { ClearIcon } from "./icons";

function AppTile({
  appId,
  setSelectedApps,
  isSelected,
  interactive,
}: {
  appId: string;
  setSelectedApps: Dispatch<SetStateAction<string[]>>;
  isSelected: boolean;
  interactive: boolean;
}) {
  const { data: app } = useApplicationQuery(appId);
  const [hovering, setHovering] = useBoolean();
  const checkIconBackdrop = useColorModeValue(
    "drop-shadow(0px 0px 10px rgba(200, 200, 200, 1))",
    "drop-shadow(0px 0px 10px rgba(0, 0, 0, 1))",
  );
  const checkIconUnselectedColor = useColorModeValue("gray.700", "gray.400");

  if (!app) {
    return <AppPreviewSkeleton />;
  }

  return (
    <Tooltip
      hidden={interactive}
      label={t("render_machine.dialog.cannot_select_tooltip")}
    >
      <Box
        pos="relative"
        cursor={interactive ? "pointer" : "not-allowed"}
        onPointerEnter={setHovering.on}
        onPointerLeave={setHovering.off}
      >
        <Box
          onClick={() => {
            if (!interactive) return;

            setSelectedApps((currentApps) => {
              const apps = currentApps ?? [];
              if (isSelected) {
                const index = apps.findIndex((id) => id === app.id);
                if (index !== -1) {
                  apps.splice(index, 1);
                }
                return apps;
              } else {
                apps.push(app.id);
                return apps;
              }
            });
          }}
          pos="relative"
        >
          <Box
            filter={hovering ? "brightness(0.7)" : ""}
            opacity={!interactive ? 0.2 : 1}
          >
            <ApplicationPreviewImage application={app} />
          </Box>

          <Box
            hidden={(!hovering && !isSelected) || !interactive}
            pos="absolute"
            left={0}
            top={0}
            w="100%"
            h="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Icon
              as={FaCheckCircle}
              boxSize={12}
              _hover={{
                color: isSelected ? checkIconUnselectedColor : "brand.500",
              }}
              color={isSelected ? "brand.500" : checkIconUnselectedColor}
              filter={checkIconBackdrop}
            />
          </Box>
        </Box>

        <LinkBox>
          <LinkOverlay
            target="_blank"
            href={generatePath(routes.application.details, {
              id: app.id,
            })}
          >
            <Heading as="h4" size="sm">
              {app.name}
            </Heading>
          </LinkOverlay>
        </LinkBox>
      </Box>
    </Tooltip>
  );
}

function CreateRenderMachineBody({ onClose }: { onClose: () => void }) {
  const [flags] = useFeatureFlags();
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const { data: appSearchResults, isSuccess: isSearchSuccess } =
    useSearchApplicationQuery(
      debouncedSearchText?.length >= 3 ? debouncedSearchText : undefined,
      {
        page_size: 15,
        supported_xr_platform_or: ["win-vr", "win-non-vr"],
        permission: "stream",
      },
    );
  const { data: organization } = useActiveOrganizationQuery();
  const { user } = useAuthentication();
  // save the selected apps in local storage based on the org and the user
  const [selectedAppsStorage, setSelectedApps, clearLocalStorage] =
    useLocalStorage<string[]>(
      `pre-installed-apps-${organization?.id}-${user?.id}`,
      [],
    );

  // obtain the details for all selected apps. this step is important as the application id is stored in loacl storage.
  // the  data needs to be queried from the server on page load / change
  const { data: appsToPreInstall } = useApplicationsQuery({
    id_in: selectedAppsStorage,
  });
  // map the application id to the application build id
  const appsBuildIdToPreinstall = useMemo<number[]>((): number[] => {
    if (
      !appsToPreInstall ||
      appsToPreInstall.results.length === 0 ||
      selectedAppsStorage.length === 0
    ) {
      return [];
    }

    return [
      // unique keys
      ...new Set<number>(
        appsToPreInstall.results
          .flatMap((app) => app.launch_configurations)
          // only select the windows apps
          .filter((launch_config) =>
            launch_config.xr_platform.startsWith("win-"),
          )
          .map((launch_config) => launch_config.application_build)
          .filter((build) => build !== null),
      ),
    ];
  }, [appsToPreInstall, selectedAppsStorage.length]);
  const [debouncedAppBuildsIdsToPreinstall] = useDebounce(
    appsBuildIdToPreinstall,
    300,
    { leading: true },
  );

  const [{ debugModeEnabled }, setFlags] = useFeatureFlags();
  const createVm = useCreateVirtualMachineMutation({
    onSuccess: (vm) => {
      setFlags((flags) => {
        return {
          ...flags,
          virtualMachineId: vm.id.toString(),
        };
      });

      onClose();
    },
  });
  // all vm sizes
  const { data: vmSizes } = useVmSizesQuery();
  // the best region according to our algorithm
  const {
    region: bestRegion,
    isSuccess: isSuccessBestRegionQuery,
    isFetched: isFetchedBestRegionQuery,
  } = useBestCloudRenderingRegionQuery({
    appIds: debouncedAppBuildsIdsToPreinstall,
    allowUnmanagedZones: false,
  });
  // the list of all regions allowed for the selected apps
  const { data: regions, isFetched: areRegionsFetched } =
    useCloudRenderingRegionsQuery({
      appIds: debouncedAppBuildsIdsToPreinstall,
      allowUnmanagedZones: false,
    });
  // the users manually selected region
  const [manuallySelectedRegion, setManuallySelectedRegions] = useState<
    Region | undefined
  >();
  // save if the user wants to manually overwrite the region
  const [regionOverwrite, setRegionOverwrite] = useState<boolean>();
  // the users selected vm size
  const [manuallySelectedVmSize, setManuallySelectedVmSize] = useState<
    string | undefined
  >();
  // save if the user wants to manually overwrite the vm size
  const [vmSizeOverwrite, setVmSizeOverwrite] = useState<boolean>();
  // take the users regions selection default to the best region
  const selectedRegion = useMemo(
    () => manuallySelectedRegion ?? bestRegion,
    [manuallySelectedRegion, bestRegion],
  );

  // take the users vm size selection default to the best vm size
  const selectedVmSize = useMemo(() => {
    const vmSizeName =
      manuallySelectedVmSize ?? selectedRegion?.supportedVmSizes[0];
    if (!vmSizes) return undefined;

    return vmSizes.find((vmSize) => vmSize.name === vmSizeName);
  }, [manuallySelectedVmSize, selectedRegion?.supportedVmSizes, vmSizes]);

  // keep all selected apps in the list, after the selected apps, show the search results
  const filteredAppIds = useMemo(() => {
    if (!appSearchResults) {
      return [];
    }

    return appSearchResults.results.filter(
      (app) => !selectedAppsStorage.includes(app.id),
    );
  }, [appSearchResults, selectedAppsStorage]);

  return (
    <>
      <Stack my={[2, 6]} mx={[4, 6]} spacing={6}>
        <Heading as="h3" size="lg">
          {t("render_machine.dialog.title")}
        </Heading>
        <Text>{t("render_machine.dialog.sub_heading")}</Text>

        <Text>
          {t("render_machine.dialog.selectNumberOfApps")}
          <span style={{ fontWeight: "bold" }}>
            <BrandedSkeleton isLoaded={!!selectedRegion} as="span">
              {selectedRegion ? (
                <>
                  {selectedRegion?.displayName} ({selectedRegion?.cloudProvider}
                  )
                </>
              ) : (
                "Unknown Region"
              )}
            </BrandedSkeleton>
          </span>{" "}
          {t("render_machine.dialog.bestRegion")}
          <span style={{ fontWeight: "bold" }}>
            <BrandedSkeleton isLoaded={!!selectedVmSize} as="span">
              {selectedVmSize?.displayName ?? "VM Size"}
            </BrandedSkeleton>
          </span>
          {". "}
          {!regionOverwrite && (
            <>
              {t("render_machine.dialog.youCan")}&nbsp;
              <Link
                color="chakra-subtle-text"
                onClick={() => setRegionOverwrite(true)}
              >
                {t("render_machine.dialog.overwriteRegion")}
              </Link>
              {". "}
            </>
          )}
          {!vmSizeOverwrite && (
            <>
              {t("render_machine.dialog.additionallyYouCan")}&nbsp;
              <Link
                color="chakra-subtle-text"
                onClick={() => setVmSizeOverwrite(true)}
              >
                {t("render_machine.dialog.overwriteMachineSize")}
              </Link>
              .
            </>
          )}
        </Text>

        {regionOverwrite && (
          <CloudRenderingRegionsTable
            regions={regions}
            isLoading={!areRegionsFetched}
            setCloudRenderingRegion={(region) => {
              setManuallySelectedRegions(region);
              setManuallySelectedVmSize(undefined);
            }}
            preferredCloudRenderRegionName={selectedRegion?.name}
          />
        )}
        {regionOverwrite && (
          <Link
            onClick={() => {
              setManuallySelectedRegions(undefined);
              setRegionOverwrite(false);
            }}
          >
            {t("render_machine.dialog.hideAndResetManualRegionSelection")}
          </Link>
        )}

        {selectedRegion && selectedVmSize && (
          <>
            {vmSizeOverwrite && (
              <VmSizeSelector
                region={selectedRegion}
                defaultVmSizeName={selectedVmSize.name}
                onVmSizeSelected={(vmSize) =>
                  setManuallySelectedVmSize(vmSize.name)
                }
              />
            )}
            {vmSizeOverwrite && (
              <Link
                onClick={() => {
                  setManuallySelectedVmSize(undefined);
                  setVmSizeOverwrite(false);
                }}
              >
                {t("render_machine.dialog.hideAndResetManualVmSizeSelection")}
              </Link>
            )}
          </>
        )}

        {selectedAppsStorage.length > 0 && (
          <>
            <SimpleGrid spacing={8} columns={[2, 3, 4, 5, 6, 7]}>
              {selectedAppsStorage.map((appId) => (
                <AppTile
                  key={appId}
                  appId={appId}
                  interactive={true}
                  setSelectedApps={setSelectedApps}
                  isSelected={true}
                />
              ))}
            </SimpleGrid>
            <Button variant="ghost" onClick={() => clearLocalStorage()}>
              {t("render_machine.dialog.clearAllPreInstalledApps")}
            </Button>
          </>
        )}

        <InputGroup>
          <InputLeftElement pointerEvents="none" height="100%">
            <Icon as={SearchIcon} />
          </InputLeftElement>
          {searchText && (
            <InputRightElement height="100%" cursor="pointer">
              <Icon as={ClearIcon} onClick={() => setSearchText("")} />
            </InputRightElement>
          )}
          <Input
            placeholder={t("search.placeholder")}
            size="lg"
            _focus={undefined}
            value={searchText}
            _placeholder={{ color: "gray.400" }}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </InputGroup>
        <Button
          colorScheme="brand"
          isLoading={
            !selectedRegion ||
            !organization ||
            !user ||
            !selectedVmSize ||
            !isSuccessBestRegionQuery ||
            !isFetchedBestRegionQuery ||
            createVm.isPending
          }
          onClick={() => {
            if (!selectedRegion || !organization || !user || !selectedVmSize) {
              return null;
            }

            createVm.mutate({
              region: selectedRegion?.name,
              size: selectedVmSize.name,
              expirationTimeSpan: "06:00:00",
              debugModeEnabled: debugModeEnabled ?? false,
              preInstalledAppBuildIds:
                appsBuildIdToPreinstall?.map((id) => id.toString()) ?? [],
              image: flags.vmImage,
            });
          }}
        >
          {t("render_machine.dialog.createRenderMachine")}
        </Button>

        <SimpleGrid spacing={8} columns={[2, 3, 4, 5, 6, 7]}>
          {filteredAppIds.map((app) => {
            return (
              <AppTile
                key={app.id}
                appId={app.id}
                interactive={appsBuildIdToPreinstall.length < 10}
                setSelectedApps={setSelectedApps}
                isSelected={false}
              />
            );
          })}
          {!isSearchSuccess &&
            Array.from({ length: 10 }).map((_, idx) => (
              <WrapItem key={idx}>
                <AppPreviewSkeleton w={[235, 245, 250]} />
              </WrapItem>
            ))}
        </SimpleGrid>
      </Stack>
    </>
  );
}

export function CreateRenderMachineDrawer({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const btnRef = useRef(null);

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      size="full"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody>
          <CreateRenderMachineBody onClose={onClose} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
